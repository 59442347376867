
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Container, Draggable } from 'vue-smooth-dnd';
import { IClientFormTasks } from '../entities/client-form-tasks.interface';
import tasksService from '../services/tasks.service';
import { ITasks } from '../entities/tasks.entity.interface';
import { ClientFormTasks } from '../entities/client-form-tasks.entity';
import { Tasks } from '../entities/tasks.entity';
import TaskCommentDialogComponent from './task-comment-dialog.component.vue';

@Component({
  components: {
    Container,
    Draggable,
    TaskCommentDialogComponent,
  },
})
export default class TaskBoardComponent extends Vue {
  @Prop({
    type: Boolean,
    default: () => false,
  })
  public readonly value!: boolean;

  @Prop({
    type: String,
    default: () => '',
  })
  public readonly companyId!: string;

  @Prop({
    type: Boolean,
    default: () => true,
  })
  public readonly showToolbar!: boolean;

  @Prop({
    type: Boolean,
    default: () => false,
  })
  public readonly showEditButton!: boolean;

  @Prop({
    type: Boolean,
    default: () => false,
  })
  public readonly showNewTabButton!: boolean;

  @Prop({
    type: String,
    default: () => '',
  })
  public readonly currentTaskId!: string;

  public loading: boolean = false;

  private tasks: ITasks[] = [];

  private columnsTasks: any[] = [];

  private showCommentDialog: boolean = false;

  private taskToCommentDialog: IClientFormTasks = new ClientFormTasks();

  public dropPlaceholderOptions = {
    className: 'drop-preview ',
    animationDuration: '150',
    showOnTop: true,
  };

  public upperDropPlaceholderOptions = {
    className: 'cards-drop-preview',
    animationDuration: '150',
    showOnTop: true,
  };

  public setTaskToCommentDialog(task: IClientFormTasks) {
    this.taskToCommentDialog = task;
  }

  public openDialog(task: IClientFormTasks) {
    this.showCommentDialog = true;
    this.setTaskToCommentDialog(task);
  }

  public canShowEditButton(task: IClientFormTasks) {
    return this.showEditButton && task.status !== 'Fechada';
  }

  public removeTask(status: any, dropResult: any) {
    const index = this.columnsTasks.findIndex(it => it.name === status);
    const indexToRemove = this.columnsTasks[index].tasks.findIndex(
      (it: any) => it.id === dropResult.payload.id,
    );

    this.columnsTasks[index].tasks.splice(indexToRemove, 1);
  }

  public goToOrder(task: IClientFormTasks) {
    // this.$router.push({
    //   name: 'client-edit',
    //   params: {
    //     id,
    //   },
    // });
  }

  public addTask(status: any, dropResult: any) {
    const index = this.columnsTasks.findIndex(it => it.name === status);
    this.columnsTasks[index].tasks.push(dropResult.payload);
  }

  public updateTask(task: IClientFormTasks) {
    this.$emit('update', task);
  }

  async onCardDrop(status: any, dropResult: any) {
    const notModified = dropResult.removedIndex === null && dropResult.addedIndex === null;
    if (notModified) return;
    const hasItem = dropResult.payload;
    if (!hasItem) return;
    const isSameItem = dropResult.removedIndex !== null && dropResult.addedIndex !== null;

    if (dropResult.payload.status === 'Fechada') return;

    if (isSameItem) return;
    if (dropResult.addedIndex !== null) {
      this.loading = true;
      this.addTask(status, dropResult);
      await tasksService.changeStatus(dropResult.payload.id, status).catch((err: any) => {
        this.$snackbar.open({
          text: err.response.data.message,
          color: 'danger',
          buttonColor: 'white',
        });
        this.loading = false;
      });

      await this.refreshBoard();
      this.loading = false;

      return;
    }
    if (dropResult.removedIndex !== null) {
      this.removeTask(status, dropResult);
      await this.refreshBoard();
    }
  }

  public async refreshBoard() {
    await this.getTasks();
    await this.getStatus();
  }

  public close(data: boolean) {
    if (data === true) return;
    this.$emit('input', false);
  }

  private async getTasks() {
    if (!this.companyId) return;
    const tasks: any = await tasksService.getAllTasks(this.companyId);
    if (tasks.length) {
      this.tasks = [];
      this.tasks = tasks
        .filter((it: ClientFormTasks) => it.id === this.currentTaskId)
        .map(
          (it: any) => new Tasks({
            ...it,
            tasks: it.tasks.map((tasksItem: ClientFormTasks) => new ClientFormTasks(tasksItem)),
          }),
        );
    }
  }

  public getTaskPayload(columnName: string, taskIndex: any) {
    return (index: any) => {
      const tasksColumn = this.columnsTasks.find(it => it.name === columnName);

      if (tasksColumn && tasksColumn.tasks.length) {
        return tasksColumn.tasks[index];
      }
      return this.tasks[taskIndex];
    };
  }

  public async getStatus() {
    const data = await tasksService.getStatusColumn();

    this.columnsTasks = data.map((column: string) => {
      const tasks = this.tasks.length
        ? this.tasks[0].tasks.filter((taskItem: any) => taskItem.status === column)
        : [];
      return {
        name: column,
        tasks,
      };
    });
  }

  public async created() {
    await this.refreshBoard();
  }
}
