import moment from '@/services/moment/moment.service';
import { ICommentTask } from './comment-task.interface';

export class CommentTask implements ICommentTask {
  public id: string;

  public created_at: string;

  public created_by: { id: string; name: string };

  public description: string;

  public updated_at: string;

  constructor(data: any = {}) {
    this.id = data.id || '';
    this.created_at = data.created_at || '';
    this.created_by = data.created_by || { id: '', name: '' };
    this.description = data.description || '';
    this.updated_at = data.updated_at || '';
  }

  public get date() {
    return this.updated_at
      ? `Atualizado em ${moment(this.updated_at).format('DD/MM/YYYY HH:mm')}`
      : `Criado em ${moment(this.created_at).format('DD/MM/YYYY HH:mm')}`;
  }
}
