
import { mapGetters } from 'vuex';
import { debounce } from 'lodash';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { IClientFormTasks } from './entities/client-form-tasks.interface';

@Component({
  computed: { ...mapGetters(['user']) },
})
export default class TaskListItemComponentVue extends Vue {
  @Prop({ type: Array, default: () => [] })
  private readonly tasks!: IClientFormTasks[];

  @Prop({ type: Boolean, default: () => false })
  private readonly hiddenActions!: boolean;

  @Prop({ type: Boolean, default: () => false })
  private readonly showLinkBtn!: boolean;

  @Prop({ type: Boolean, default: () => false })
  private readonly flat!: boolean;

  @Prop({ type: String, default: () => '', required: true })
  private readonly orderId!: string;

  public user!: any;

  public isOwner(item: IClientFormTasks) {
    return this.user.id === item.created_by.id;
  }

  public toggleTasks = debounce((data: IClientFormTasks, event: boolean) => {
    this.toggleTaskCompleted(data, event);
  }, 600);

  public async toggleTaskCompleted(data: IClientFormTasks, event: boolean) {
    this.$emit('toggle-task-completed', { data, event });
  }

  private goToOrder() {
    if (!this.orderId) return;
    const routeData = this.$router.resolve({
      name: 'client-edit',
      params: {
        id: this.orderId,
      },
    });
    window.open(routeData.href, '_blank');
  }

  public editItem(itemToEdit: IClientFormTasks) {
    this.$emit('edit-item', itemToEdit);
  }

  public deleteComment(itemToDelete: IClientFormTasks) {
    this.$emit('delete-item', itemToDelete);
  }
}
