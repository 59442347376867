import { User } from '@/modules/Administrative/modules/Users/entities/user.entity';
import tasksService from '@/modules/Clients/components/tasks/services/tasks.service';
import moment from '@/services/moment/moment.service';
import { IClientFormTasks, UserTask } from './client-form-tasks.interface';
import { CommentTask } from './comment-task.entity';
import { ICommentTask } from './comment-task.interface';

export class ClientFormTasks implements IClientFormTasks {
  id: string;

  description: string;

  users: any;

  created_at: string;

  created_by: UserTask;

  updated_at: string;

  closed_at: string;

  status: string;

  completed: boolean;

  comments: ICommentTask[];

  constructor(
    data: IClientFormTasks = {
      id: '',
      description: '',
      users: [],
      created_at: '',
      created_by: { id: '', name: '' },
      updated_at: '',
      completed: false,
      closed_at: '',
      status: '',
      comments: [],
    },
  ) {
    this.id = data.id;
    this.description = data.description;
    this.users = data.users && data.users.length ? data.users.map((user: User) => new User(user)) : [];
    this.created_at = data.created_at;
    this.created_by = data.created_by;
    this.updated_at = data.updated_at;
    this.completed = !!data.closed_at;
    this.closed_at = data.closed_at;
    this.status = data.status;
    this.comments = data.comments && data.comments.length
      ? data.comments.map((it: any) => new CommentTask(it))
      : [];
  }

  public get dateUpdated() {
    if (this.updated_at) {
      return moment(this.updated_at).format('DD/MM/YYYY HH:mm:ss');
    }
    return moment(this.created_at).format('DD/MM/YYYY HH:mm:ss');
  }

  public get createdDateFormatted() {
    return moment(this.created_at).format('DD/MM/YYYY HH:mm:ss');
  }

  public get closedAtDateFormatted() {
    return this.closed_at ? moment(this.closed_at).format('DD/MM/YYYY HH:mm:ss') : '';
  }

  private format() {
    return {
      ...this,
      users: this.users.id ? [this.users.id] : this.users.map((it: any) => it.id),
    };
  }

  public create(order_id: string) {
    return tasksService.create({ ...this.format(), order_id });
  }

  public update() {
    return tasksService.update(this.format());
  }

  public delete() {
    return tasksService.delete(this.id);
  }
}
