
import { Component, Vue, Prop } from 'vue-property-decorator';
import moment from '@/services/moment/moment.service';
import DeleteDialog from '@/components/dialogs/delete-dialog.component.vue';
import { IClientFormTasks } from '../entities/client-form-tasks.interface';
import { ClientFormTasks } from '../entities/client-form-tasks.entity';
import commentTaskService from '../services/comment-task.service';
import { ICommentTask } from '../entities/comment-task.interface';
import tasksService from '../services/tasks.service';
import { CommentTask } from '../entities/comment-task.entity';

@Component({
  components: {
    DeleteDialog,
  },
})
export default class TaskCommentDialogCardComponent extends Vue {
  public $refs: any;

  @Prop({ type: Boolean, default: () => false })
  public readonly value!: boolean;

  @Prop({ type: Object, default: () => new ClientFormTasks() })
  public readonly task!: IClientFormTasks;

  private description: string = '';

  private showDialogDelete: boolean = false;

  private comments: ICommentTask[] = [];

  private itemToDelete: ICommentTask = new CommentTask();

  private itemToEdit: ICommentTask = new CommentTask();

  private get isClosed() {
    return this.task.status === 'Fechada';
  }

  private get buttonLabel() {
    return !this.itemToEdit.id ? 'Inserir comentário' : 'Editar Comentário';
  }

  private close() {
    this.$emit('input', false);
  }

  private deleteComment() {
    commentTaskService
      .deleteComment(this.itemToDelete.id)
      .then(() => {
        this.$snackbar.open({
          text: 'Comentário excluido com sucesso',
          color: 'success',
          buttonColor: '#fff',
        });
        this.getTaskById();
      })
      .finally(() => {
        this.showDialogDelete = false;
      });
  }

  public setShowDialogDelete(comment: ICommentTask) {
    this.itemToDelete = comment;
    this.showDialogDelete = true;
  }

  public closeDialogDelete() {
    this.showDialogDelete = false;
  }

  private scrollToTop() {
    this.$refs.taskCommentDialogCard.scroll({ top: 0, behavior: 'smooth' });
  }

  private setCommentToEdit(comment: ICommentTask) {
    this.description = comment.description;
    this.itemToEdit = comment;
    this.scrollToTop();
  }

  private editComment() {
    const payload: any = {
      ...this.itemToEdit,
      description: this.description,
      order_task_id: this.task.id,
    };
    commentTaskService.editComment(payload.id, payload).then(() => {
      this.$snackbar.open({
        text: 'Comentário adicionado com sucesso',
        color: 'success',
        buttonColor: '#fff',
      });
      this.itemToEdit = new CommentTask();
      this.description = '';
      this.getTaskById();
    });
  }

  private insertComment() {
    const payload: any = {
      description: this.description,
      order_task_id: this.task.id,
    };

    commentTaskService.createComment(payload).then(() => {
      this.$snackbar.open({
        text: 'Comentário adicionado com sucesso',
        color: 'success',
        buttonColor: '#fff',
      });
      this.getTaskById();
    });
  }

  private saveComment() {
    if (this.itemToEdit.id) {
      this.editComment();
      return;
    }
    this.insertComment();
  }

  private async getTaskById() {
    const task = await tasksService.getTaskById(this.task.id);
    this.comments = task.comments
      .map((it: any) => new CommentTask(it))
      .sort((a: ICommentTask, b: ICommentTask) => {
        if (moment(b.updated_at).isSameOrBefore(a.updated_at)) {
          return -1;
        }

        return 1;
      });
  }

  created() {
    this.getTaskById();
  }
}
