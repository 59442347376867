import moment from '@/services/moment/moment.service';
import { ClientFormTasks } from './client-form-tasks.entity';
import { IClientFormTasks } from './client-form-tasks.interface';
import { ITasks } from './tasks.entity.interface';

export class Tasks implements ITasks {
  private created_at: string;

  public created_by_name: string;

  public enterprise_name: string;

  public proponent_name: string;

  public tasks_count: string;

  public unit_name: string;

  public id: string;

  public name: string;

  public status: string;

  public tasks: IClientFormTasks[];

  constructor(data: any = {}) {
    this.created_at = data.created_at;
    this.created_by_name = data.created_by_name;
    this.enterprise_name = data.enterprise_name;
    this.proponent_name = data.proponent_name;
    this.tasks_count = data.tasks_count;
    this.unit_name = data.unit_name;
    this.id = data.id || '';
    this.name = data.proponent_name || '';
    this.status = data.staus || '';
    this.tasks = data.tasks.length ? data.tasks.map((it: any) => new ClientFormTasks(it)) : [];
  }

  public get createdByfirstName() {
    return this.created_by_name.split(' ')[0];
  }

  public get createdAt() {
    return String(moment(this.created_at).format('DD/MM/YYYY'));
  }

  public get isAllCompleted() {
    return this.tasks.every(it => it.completed);
  }
}
