import http from '@/services/http.service';
import { ICommentTask } from '../entities/comment-task.interface';
import { ICommentTaskService } from './comment-task-service.interface';

class CommentTaskService implements ICommentTaskService {
  async createComment(comment: ICommentTask): Promise<any> {
    return http.post('/order-task-comment', comment);
  }

  async editComment(commentId: string, comment: ICommentTask): Promise<any> {
    return http.put(`/order-task-comment/${commentId}`, comment);
  }

  async deleteComment(id: string): Promise<any> {
    return http.delete(`/order-task-comment/${id}`);
  }
}

export default new CommentTaskService();
