import http from '@/services/http.service';
import { IClientFormTasks } from '../entities/client-form-tasks.interface';

class TasksService {
  getAllByClientId(order_id: string) {
    return http
      .get('order-task', {
        params: {
          order_id,
        },
      })
      .then(({ data }) => data.data);
  }

  create(task: any) {
    return http.post('order-task', task);
  }

  doneTask(task: IClientFormTasks) {
    return http.put(`order-task/make-done/${task.id}/`, task);
  }

  undoTask(task: IClientFormTasks) {
    return http.put(`order-task/make-undone/${task.id}/`, task);
  }

  update(task: any) {
    return http.put(`order-task/${task.id}`, task);
  }

  delete(taskId: string) {
    return http.delete(`order-task/${taskId}`);
  }

  getTaskById(taskId: string) {
    return http.get(`/order-task/${taskId}`).then(({ data }) => data.data);
  }

  getAllTasks(companyId: string, filter?: any) {
    return http
      .get('order-task/grouped', { params: { ...filter, company_id: companyId } })
      .then(({ data }) => data.data);
  }

  getStatusColumn() {
    return http.get('order-task/statuses').then(({ data }) => data.data);
  }

  changeStatus(order_task_id: string, status: string) {
    return http
      .post('order-task/change-status', {
        order_task_id,
        status,
      })
      .then(({ data }) => data.data);
  }
}

export default new TasksService();
